import React from 'react'
import Image from 'next/image'
import { Container } from '@ui/components/Container'
import { InstantCashDisclosure } from '@ui/components/Disclosure'

export const ATMInstantCashCopy: React.FC<{
  amount?: number
  className?: string
}> = ({ amount = 50, className }) => {
  return (
    <div className={className}>
      <Container>
        <div className="flex flex-col md:flex-row">
          <div className="px-4 py-8 md:w-3/5 md:py-16">
            <h3 className="h2 font-montserrat">
              ATM <span className="whitespace-pre">Instant Cash™</span>
            </h3>
            <InstantCashDisclosure className="mt-4" />
            <ul className="mt-4 list-none space-y-8 text-lg md:text-xl">
              <li>
                <h4 className="h4">
                  {amount && amount > 0
                    ? `Get up to $${amount}*`
                    : 'Get cash when you need it'}
                </h4>
                <p>
                  Get cash instantly when you need it. No interest. No credit
                  check. No late fees. Not a personal loan. We&apos;re not
                  lenders or a bank.
                </p>
              </li>
              <li>
                <h4 className="h4">Avoid Overdraft Fees </h4>
                <p>
                  Help avoid overdraft fees and welcome emergency funds to
                  assist with unexpected expenses.
                </p>
              </li>
              <li>
                <h4 className="h4">Pay $3.99/month</h4>
                <p>
                  Unlock the power of hassle-free cash access. As a member, you
                  have easy access to cash whenever you need it. No more
                  repeated applications—your membership takes care of it all.
                </p>
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-center pb-8 md:ml-8 md:py-0">
            <Image
              src="/svg/1-ant-with-card.svg"
              alt="ATM Ant with Card"
              width={499}
              height={367}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
