import { useId } from 'react'
import clsx from 'clsx'

export const formClasses =
  'block w-full appearance-none rounded-lg border shadow-sm border-gray-300 p-2 focus:border-blueberry-500 focus:outline-none focus:ring-blueberry-500 sm:text-sm'

export function Label({
  id,
  children,
  required = false,
}: {
  id: string
  children: React.ReactNode
  required?: boolean
}) {
  return (
    <label htmlFor={id} className="mb-2 block text-sm font-semibold">
      {children}
      {required && '*'}
    </label>
  )
}

export function TextField({
  label,
  type = 'text',
  className,
  required = false,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label?: string }) {
  const id = useId()

  return (
    <div className={className}>
      {label && (
        <Label id={id} required={required}>
          {label}
        </Label>
      )}
      <input
        id={id}
        type={type}
        {...props}
        className={formClasses}
        required={required}
      />
    </div>
  )
}

export function SelectField({
  label,
  className,
  required = false,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'select'>, 'id'> & { label?: string }) {
  const id = useId()

  return (
    <div className={className}>
      {label && (
        <Label id={id} required={required}>
          {label}
        </Label>
      )}
      <select
        id={id}
        {...props}
        className={clsx(formClasses, 'pr-8')}
        required={required}
      />
    </div>
  )
}

export function DateField({
  label,
  className,
  required = false,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label?: string }) {
  const id = useId()

  return (
    <div className={className}>
      {label && (
        <Label id={id} required={required}>
          {label}
        </Label>
      )}
      <input
        id={id}
        type="date"
        {...props}
        className={formClasses}
        required={required}
      />
    </div>
  )
}
