import Link from 'next/link'
import {
  GooglePlayStoreWhiteSvg,
  GooglePlayStoreBlackSvg,
  AppStoreWhiteSvg,
  AppStoreBlackSvg,
} from '@ui/components/Svg'
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '@ui/constants'

type Variant = 'dark' | 'black' | 'light' | 'white'

type Props = {
  className?: string
  variant?: Variant
}

export const ButtonAppStore = ({ className, variant = 'dark' }: Props) => {
  return (
    <div className={className}>
      <Link href={APP_STORE_LINK} className="h-full w-full">
        {variant === 'dark' || variant === 'black' ? (
          <AppStoreBlackSvg className="h-full w-full" />
        ) : (
          <AppStoreWhiteSvg className="h-full w-full" />
        )}
      </Link>
    </div>
  )
}

export const ButtonGooglePlay = ({ className, variant = 'dark' }: Props) => {
  return (
    <div className={className}>
      <Link href={GOOGLE_PLAY_LINK} className="h-full w-full">
        {variant === 'dark' || variant === 'black' ? (
          <GooglePlayStoreBlackSvg className="h-full w-full" />
        ) : (
          <GooglePlayStoreWhiteSvg className="h-full w-full" />
        )}
      </Link>
    </div>
  )
}
