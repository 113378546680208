import { Container } from '@ui/components/Container'
import clsx from 'clsx'

type Props = {
  className?: string
  children?: React.ReactNode
}

export const MainBlockRewards = ({ className, children }: Props) => {
  return (
    <div id="rewards" className={clsx('bg-off-white py-20', className)}>
      <Container>
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex flex-col gap-4 lg:w-1/2">
            <h2 className="font-montserrat h2">Rewards & Cashback</h2>
            <p className="body-2 text-dolphin-1000">
              At ATM.com, we make it easy for you to earn extra cash while
              managing your finances. Discover the rewarding opportunities that
              fit seamlessly into your life:
            </p>
            <ul className="body-2 text-dolphin-1000 list-disc space-y-6 pl-5">
              <li>
                <strong>Game Rewards</strong>
                <br /> Turn your gaming time into real money. Play popular games
                like Coin Master, Solitaire, and more, with the potential to
                earn up to $250 a month. It's fun, easy, and rewarding.
              </li>
              <li>
                <strong>Paid Surveys</strong>
                <br /> Share your opinions and earn! With quick and easy surveys
                that pay between $0.03 and $5.00 each, it's a simple and
                consistent way to boost your income. New surveys are added
                weekly.
              </li>
              <li>
                <strong>Cashback Offers</strong>
                <br /> Save money on your everyday purchases with cashback from
                over 500 verified partners. Whether you're buying groceries,
                fashion, or electronics, earn cashback effortlessly and grow
                your savings.
              </li>
              <li>
                <strong>Location Rewards</strong>
                <br /> Earn money passively by enabling location sharing. As you
                go about your day, you'll earn cash rewards just for visiting
                stores, making it one of the easiest ways to increase your
                earnings.
              </li>
              <li>
                <strong>Daily Rewards</strong>
                <br /> Get rewarded daily by answering a few quick questions. It
                takes less than a minute, and nearly a million users are already
                benefiting from this convenient daily earning opportunity.
              </li>
            </ul>
            <p className="body-2 text-dolphin-1000">
              ATM.com is designed to help you earn and save in ways that fit
              seamlessly into your lifestyle. Download the app today and start
              making the most of your time and money!
            </p>
          </div>
          <div className="flex-auto lg:w-1/2">{children}</div>
        </div>
      </Container>
    </div>
  )
}

export const MainBlockCashRewards = ({ className, children }: Props) => {
  return (
    <div id="rewards" className={clsx('bg-white py-20', className)}>
      <Container>
        <div className="flex flex-col items-center gap-8 xl:grid xl:grid-cols-2 xl:gap-16">
          <div className="flex flex-col gap-4 xl:order-2">
            <h2 className="font-montserrat h2">Earn Money with ATM.com</h2>
            <p className="body-2">
              ATM.com makes it <em>simple</em> to earn and save. We’re{' '}
              <strong>trusted by millions</strong> for one reason:{' '}
              <strong>we pay you in real cash</strong>. Here's what sets us
              apart:
            </p>
            <ul className="body-2 list-disc space-y-6 pl-5">
              <li>
                <strong>No Points, Only Cash</strong>
                <br />
                Withdraw funds straight to your bank.
              </li>
              <li>
                <strong>Multiple Earning Options</strong>
                <br />
                Surveys, games, cashback and rewards from over 500+ partners.
              </li>
              <li>
                <strong>Safe & Secure</strong>
                <br />
                We use industry-standard encryption to protect your information.
              </li>
              <li>
                <strong>High Earning Potential</strong>
                <br />
                Earn up to $250/month* simply by doing what you love.
              </li>
            </ul>
            <p className="content-disclosure text-dolphin-900">
              *Actual earnings vary by participation.
            </p>
          </div>
          <div className="flex-auto xl:order-1">{children}</div>
        </div>
      </Container>
    </div>
  )
}

export const MainBlockHowYouEarn = ({ className, children }: Props) => {
  return (
    <div id="how-your-earn" className={clsx('bg-white py-20', className)}>
      <Container>
        <div className="flex flex-col items-center gap-8 xl:grid xl:grid-cols-2 xl:gap-16">
          <div className="flex flex-col gap-4">
            <h2 className="font-montserrat h2">How You Earn</h2>
            <ol className="body-2 list-decimal space-y-6 py-6 pl-6">
              <li>
                <strong>Play & Earn</strong>
                <br />
                Play popular games like <strong>Coin Master</strong> and{' '}
                <strong>Solitaire</strong> to earn real cash—no extra work
                needed.
              </li>
              <li>
                <strong>Quick Surveys</strong>
                <br />
                Share your opinions with surveys that pay up to{' '}
                <strong>$5.00</strong> each. New opportunities added weekly.
              </li>
              <li>
                <strong>Cashback Offers</strong>
                <br />
                Save money on everyday purchases from <strong>
                  over 500
                </strong>{' '}
                trusted partners, including groceries, fashion, and electronics.
              </li>
              <li>
                <strong>Daily Rewards</strong>
                <br />
                Answer a quick daily question (takes less than a minute!) to get
                a little cash boost each day.
              </li>
              <li>
                <strong>Daily & Location Rewards</strong>
                <br />
                Answer quick daily questions to boost your earnings each day,
                and earn even more by enabling location sharing.
              </li>
              <li>
                <strong>Real Cash Payouts</strong>
                <br />
                <strong>No gift cards or points.</strong> ATM.com deposits real
                cash into your bank account or lets you withdraw it at an ATM.
              </li>
            </ol>
            <p className="body-2">
              <strong>Start earning today</strong> by creating your ATM.com
              account. With <strong>millions of users</strong> already
              benefiting, it's time to put more money in your pocket!
            </p>
          </div>
          <div className="flex-auto">{children}</div>
        </div>
      </Container>
    </div>
  )
}
