export type FAQ = {
  question: string
  answer: string
}

type Props = {
  amount?: number
}

export const faqs = ({ amount = 50 }: Props): FAQ[] => {
  return [
    {
      question: 'How much can I request at a time?',
      answer: `From $25${amount > 25 ? ` all the way to $${amount}` : ''} depending on your history and financial habits.`,
    },
    {
      question: 'What determines my eligibility and advance amount?',
      answer:
        'Eligibility and the advance amount are determined by a computer analysis of your primary linked bank account, using up to two years of historical income and expense data, along with other criteria. Your account must have a minimum of three consistent current direct deposits from the same source, a positive balance, and must meet other specific requirements.',
    },
    {
      question: 'Does Instant Cash impact my credit score or consider it?',
      answer:
        'No, ATM Instant Cash™ does not impact your credit score and does not consider it. There is no credit check involved, and ATM.com does not report to credit bureaus.',
    },
    {
      question: 'When do I repay my advance?',
      answer:
        'You repay your advance on the date of your next regularly scheduled direct deposit into your linked bank account. The repayment is automatically deducted or you can pay it back early without penalty.',
    },
    {
      question: 'Do I need to be a full-time employee?',
      answer:
        'No, there is no requirement to be a full-time employee. However, you must have a bank account with consistent direct deposits from the same source to be eligible.',
    },
    {
      question: 'How long does it take to receive my advance?',
      answer:
        'You can typically receive your advance within seconds after the request is approved, regardless of business hours or holidays.',
    },
    {
      question: 'How long does it take to get approved?',
      answer:
        'Approval time is variable and determined by the time it takes to analyze your account and ensure eligibility criteria are met.',
    },
    {
      question: "What if I'm not eligible?",
      answer:
        'If you are not eligible, your application will be declined, but you may re-apply after 30 days from the date of the decline notice.',
    },
    {
      question: 'How do you help break debt cycles?',
      answer:
        'ATM Instant Cash™ helps break debt cycles by offering non-recourse cash advances that do not involve high-interest loans or credit checks. This allows you to manage emergency expenses and avoid overdraft fees without incurring additional debt.',
    },
    {
      question: 'Are there any fees?',
      answer:
        "Yes, there are fees associated with ATM Instant Cash™. The Transaction Fee varies depending on the amount of the advance, ranging from $5 to 5% of the advance amount. Additionally, there is a $3.99 monthly Membership Fee for access to ATM.com's Personal Finance tools, which includes ATM Instant Cash™.",
    },
  ]
}

export const earnFaqs = (): FAQ[] => {
  return [
    {
      question: 'How do I earn money using the ATM.com app?',
      answer: `Complete surveys, play games, earn cashback, and more. Just pick your favorite ways to earn!`,
    },
    {
      question: 'How do I get paid?',
      answer: `Withdraw your earnings directly to your linked bank account from the ATM mobile app.`,
    },
    {
      question: 'How much can I earn with ATM.com?',
      answer: `Earnings vary based on your activity, but consistent use can result in monthly earnings of up to $250 or more.`,
    },
    {
      question: 'What types of cashback offers are available?',
      answer: `You can earn cashback on purchases from over 500 trusted partners, including popular brands in groceries, fashion, electronics, and more.`,
    },
    {
      question: 'Is ATM.com safe to use?',
      answer: `Absolutely. ATM.com uses industry-standard security measures to protect your personal and financial information.`,
    },
    {
      question: 'Do I need to share my location to use the app?',
      answer: `Sharing your location is optional but enables you to earn passive location rewards by visiting stores throughout your day.`,
    },
    {
      question: 'Are there limits on how much I can earn?',
      answer: `There’s no hard limit, but earnings depend on your activity level, participation in surveys and games, and the offers you engage with.`,
    },
    {
      question: 'Why should I trust ATM.com?',
      answer: `ATM.com is trusted by millions of users and partners with top brands to provide real cash rewards. Your privacy and security are our top priority.`,
    },
  ]
}
