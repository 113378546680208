import React from 'react'
import clsx from 'clsx'

type Variant =
  | 'default'
  | 'off-white'
  | 'white-blueberry-700'
  | 'white-black'
  | 'blueberry-700'
  | 'blueberry-1000'

type Props = {
  children?: React.ReactNode
  className?: string
  variant?: Variant
}

const variantClasses: Record<Variant, string> = {
  default: 'bg-blueberry-700 text-white',
  'off-white': 'bg-off-white text-blueberry-700',
  'white-blueberry-700': 'bg-white text-blueberry-700',
  'white-black': 'bg-white text-black',
  'blueberry-700': 'bg-blueberry-700 text-white',
  'blueberry-1000': 'bg-blueberry-1000 text-white',
}

export const MainBlockTitleUnderline = ({
  children,
  className,
  variant = 'default',
}: Props) => (
  <div
    className={clsx(
      'flex flex-col items-center justify-center',
      variantClasses[variant],
      className,
    )}
  >
    <h3
      className={clsx(
        'm-0 w-full text-center text-[32px] font-bold leading-[36px] tracking-[-0.03em]',
        'lg:text-[56px] lg:leading-[64px]',
        'px-[24px] pb-[28px] pt-[30px]',
        'lg:px-[24px] lg:pb-[80px] lg:pt-[80px]',
        'max-w-[375px] lg:max-w-[756px]',
        'lg:relative',
      )}
    >
      {children || (
        <>
          An App Designed to Put{' '}
          <span className="highlight text-inherit max-lg:text-amber-600">
            More Money
          </span>{' '}
          in Your Pocket
        </>
      )}
    </h3>
    <style jsx>{`
      @media (min-width: 1024px) {
        .highlight::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0%;
          width: 49%;
          height: 29%;
          background-image: url('/svg/underline.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    `}</style>
  </div>
)
