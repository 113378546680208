import clsx from 'clsx'
import { Popover, PopoverPanel, PopoverButton } from '@headlessui/react'

interface Props {
  className?: string
  buttonClassName?: string
  title?: string
}

export const AdvertiserDisclosure = ({
  className,
  buttonClassName,
  title,
}: Props) => (
  <Popover className={clsx('relative', className)}>
    <PopoverButton
      as="button"
      className={clsx(
        buttonClassName,
        'm-0 inline p-0 text-sm text-gray-500 underline hover:text-gray-700 focus:outline-none active:text-gray-900',
      )}
    >
      {title || 'Advertiser Disclosure'}
    </PopoverButton>
    <PopoverPanel
      anchor="bottom end"
      transition
      className="z-30 m-1 min-w-[300px] origin-top rounded-2xl border bg-white p-4 shadow-sm transition duration-300 ease-out data-[closed]:opacity-0 sm:w-[400px]"
    >
      <p className="p-2 text-sm leading-5 text-gray-500">
        The offers that appear are from companies which ATM.com and its partners
        receive compensation. This compensation may influence the selection,
        appearance, and order of appearance of the offers listed below. However,
        this compensation also facilitates the provision by ATM.com of certain
        services to you at no charge. The offers shown below do not include all
        companies or all of their available product and service offerings.
      </p>
    </PopoverPanel>
  </Popover>
)

export const AffiliateDisclosure = ({ className }: Props) => (
  <p
    className={clsx(
      'content-disclosure bg-dolphin-100 rounded-lg p-4',
      className,
    )}
  >
    We may receive compensation from the products and services featured on this
    site. This may influence the placement of offers, but our opinions remain
    our own. Not all available products or offers are included.
  </p>
)

export const InstantCashDisclosure = ({ className }: Props) => (
  <p className={clsx('content-disclosure text-dolphin-900', className)}>
    *Eligibility is based on review and approval. Not all applicants will be
    eligible for ATM Instant Cash™.{' '}
    <span className="whitespace-pre">Transfer fees may apply.</span>
  </p>
)

export const FooterDisclosure = ({ className }: Props) => (
  <div className={clsx('content-disclosure text-left lg:mt-6', className)}>
    ATM.com&reg; may receive affiliate compensation for purchases made from
    affiliate sellers when you click on their links on our site. The opinions
    expressed are solely those of the author and have not been influenced or
    endorsed by any financial institution or credit card issuer. While we aim to
    provide accurate and current information, we cannot guarantee its
    completeness or accuracy. You are responsible for verifying the information
    and making your own financial decisions. ATM.com does not offer credit cards
    or make credit decisions.
  </div>
)
