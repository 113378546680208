import { useId, useState } from 'react'
import { formClasses, Label } from '@ui/components/inputs/Fields'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'

export function PasswordField({
  label,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label?: string }) {
  const id = useId()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div>
      {label && (
        <Label id={id} required>
          {label}
        </Label>
      )}
      <div className="relative">
        <input
          id={id}
          type={showPassword ? 'text' : 'password'}
          name="password"
          autoComplete="current-password"
          required
          {...props}
          className={formClasses}
        />
        <span
          className="text-blueberry-700 absolute inset-y-0 right-3 flex cursor-pointer items-center text-xs"
          onClick={() => setShowPassword(!showPassword)}
          aria-label={showPassword ? 'Hide password' : 'Show password'}
        >
          {showPassword ? (
            <EyeIcon className="size-5" />
          ) : (
            <EyeSlashIcon className="size-5" />
          )}
        </span>
      </div>
    </div>
  )
}
