import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'
import UAParser from 'ua-parser-js'
import { useSessionStore } from '@web-app/stores/sessionStore'
import type {
  UserAgent,
  BrowserFingerprint,
  SessionId,
  Token,
} from '@web-app/lib/ironSession'

const NEXT_PUBLIC_GRAPHQL_API_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT

if (!NEXT_PUBLIC_GRAPHQL_API_ENDPOINT) {
  throw new Error(
    'Environment variable NEXT_PUBLIC_GRAPHQL_API_ENDPOINT is not defined!',
  )
}

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null

const createApolloClient = (
  browserFingerprint?: BrowserFingerprint,
  sessionId?: SessionId,
  userAgent?: UserAgent,
  token?: Token,
) => {
  const authLink = setContext((operation, previousContext) => {
    const { headers = {} } = previousContext

    let latestToken: Token | undefined
    let uaParser
    let currentUrl
    let userAgentHeader = {}

    if (typeof window !== 'undefined') {
      // Client-side: Access Zustand store
      const { sessionData } = useSessionStore.getState()
      latestToken = sessionData.user?.token

      uaParser = new UAParser(window.navigator.userAgent)
      currentUrl = window.location.href
      userAgentHeader = { 'User-Agent': window.navigator.userAgent }
    } else {
      // Server-side: Use token passed as parameter
      latestToken = token

      if (userAgent) {
        uaParser = new UAParser(userAgent)
        userAgentHeader = { 'User-Agent': userAgent }
      } else {
        uaParser = new UAParser()
      }
    }

    const browser = uaParser.getBrowser()
    const device = uaParser.getDevice()
    const os = uaParser.getOS()

    const osName = os.name?.toLowerCase() || 'unknown'
    const osVersion = os.version?.toLowerCase() || 'unknown'
    const deviceModel = device.model?.toLowerCase() || ''
    const deviceVendor = device.vendor?.toLowerCase() || ''

    const authHeaders = latestToken
      ? { Authorization: `Bearer ${latestToken.replace('Bearer ', '')}` }
      : {}
    const clientUdidHeader = browserFingerprint
      ? { 'X-Client-Udid': browserFingerprint }
      : {}
    const currentUrlHeader = currentUrl ? { 'X-Current-Url': currentUrl } : {}
    const sessionIdHeader = sessionId ? { 'X-Session-Id': sessionId } : {}
    const additionalHeaders = {
      'X-Client-Os': osName,
      'X-Client-Os-Version': osVersion,
      'X-Client-Browser': browser.name || 'unknown',
      'X-Client-Browser-Version': browser.version || 'unknown',
      'X-Client-Hardware':
        [deviceVendor, deviceModel].filter(Boolean).join(' ') || '',
      'X-Client-Platform': 'web',
      'X-Client-App': process.env.NEXT_PUBLIC_ANALYTICS_APP_ID || 'unknown',
      'X-Client-Build': '1.0',
    }

    return {
      headers: {
        ...headers,
        ...authHeaders,
        ...clientUdidHeader,
        ...userAgentHeader,
        ...currentUrlHeader,
        ...sessionIdHeader,
        ...additionalHeaders,
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    // https://the-guild.dev/graphql/apollo-angular/docs/data/error-handling
    // graphQLErrors: An array of errors from the GraphQL endpoint
    // networkError: any error during the link execution or server response
    // response: The response from the server
    // operation: The Operation that errored
    // forward: A function for executing the next link in the chain

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        )

        // Check if the error message is a permission error
        if (message.includes('hidden due to permissions')) {
          // Redirect to logout route
          if (typeof window !== 'undefined') {
            window.location.href = '/auth/logout'
          }
        }
      })
    }

    if (networkError) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorDetails: any = {}

      errorDetails.string = String(networkError)

      if ('name' in networkError) {
        errorDetails.name = networkError.name
      }
      if ('message' in networkError) {
        errorDetails.message = networkError.message
      }
      if ('stack' in networkError) {
        errorDetails.stack = networkError.stack
      }
      if ('statusCode' in networkError) {
        errorDetails.statusCode = networkError.statusCode
      }
      if ('response' in networkError) {
        errorDetails.response = {
          url: networkError.response.url,
          status: networkError.response.status,
          statusText: networkError.response.statusText,
          headers: networkError.response.headers,
        }
      }
      if ('bodyText' in networkError) {
        errorDetails.bodyText = networkError.bodyText
      }
      if ('result' in networkError) {
        errorDetails.result = networkError.result
      }

      console.error(`[Network error]: ${JSON.stringify(errorDetails, null, 2)}`)
      console.error(`[GraphQL Request]: ${operation.operationName}`)
      console.error(`[GraphQL Query]: ${operation.query.loc?.source.body}`)
      console.error(
        `[GraphQL Variables]: ${JSON.stringify(operation.variables, null, 2)}`,
      )

      const context = operation.getContext()
      console.error(`[Headers]: ${JSON.stringify(context.headers, null, 2)}`)
      console.error(`[Credentials]: ${context.credentials}`)
      console.error(
        `[Fetch Options]: ${JSON.stringify(context.fetchOptions, null, 2)}`,
      )
      console.error(`[URI]: ${context.uri}`)
      if (context.response) {
        console.error(`[Response Status]: ${context.response.status}`)
        console.error(`[Response Status Text]: ${context.response.statusText}`)
      }
    }
  })

  const supportsKeepalive = () => {
    if (typeof window === 'undefined') {
      return false
    }

    try {
      new Request('_', { keepalive: true })
      return true
    } catch (error) {
      console.error('Error checking keepalive support:', error)
      return false
    }
  }

  const conditionalFetchOptionsLink = new ApolloLink((operation, forward) => {
    if (operation.operationName === 'PublishWebEvent' && supportsKeepalive()) {
      operation.setContext({
        fetchOptions: {
          // The keepalive option can be used to allow the request to outlive the page.
          // Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API.
          keepalive: true,
        },
      })
    }

    return forward(operation)
  })

  const httpLink = new HttpLink({
    uri: NEXT_PUBLIC_GRAPHQL_API_ENDPOINT,
    // The 'credentials' option specifies how cookies are sent with cross-origin requests.
    // 'same-origin' means cookies are only sent if the request is for the same origin as the calling script.
    // credentials: 'same-origin',
    //
    // You can disable result caching here if you want to
    // (this does not work if you are rendering your page with `export const dynamic = "force-static"`)
    // fetchOptions: { cache: "no-store" },
  })

  const link = ApolloLink.from([
    authLink,
    errorLink,
    conditionalFetchOptionsLink,
    httpLink,
  ])

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          balance: {
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
    },
  })

  // Initialize cache persistence separately
  if (typeof window !== 'undefined') {
    // Start the persistence, but don't wait for it
    persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
      maxSize: 2097152, // 2MB
      debug: process.env.NODE_ENV === 'development',
      trigger: 'write',
    }).catch((err) => {
      console.error('Error initializing cache persistence:', err)
    })
  }

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache,
  })
}

export const initializeApollo = (
  initialState: NormalizedCacheObject = {},
  browserFingerprint?: BrowserFingerprint,
  sessionId?: SessionId,
  userAgent?: UserAgent,
  token?: Token,
) => {
  // On the server, always create a new client (because each request is isolated)
  if (typeof window === 'undefined') {
    const newClient = createApolloClient(
      browserFingerprint,
      sessionId,
      userAgent,
      token,
    )
    if (initialState) {
      newClient.cache.restore({
        ...newClient.extract(),
        ...initialState,
      })
    }
    return newClient
  }

  // On the client, reuse the same client if it already exists
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(
      browserFingerprint,
      sessionId,
      userAgent,
      token,
    )

    // If there's initial state to restore, do it the first time
    if (initialState) {
      globalApolloClient.cache.restore({
        ...globalApolloClient.extract(),
        ...initialState,
      })
    }
  }

  return globalApolloClient
}

export default createApolloClient
