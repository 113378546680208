export const AgreeToTerms = () => {
  return (
    <div className="mt-4">
      <p className="text-center text-sm">
        By proceeding, I confirm that I am 18 years or older and agree to the{' '}
        <a
          href="https://www.atm.com/privacy-policy/"
          className="text-blueberry-700 hover:text-blueberry-900 whitespace-pre underline"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href="https://www.atm.com/terms-of-service/"
          className="text-blueberry-700 hover:text-blueberry-900 whitespace-pre underline"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
        .
      </p>
    </div>
  )
}
