import React from 'react'

export const VerificationText = () => {
  return (
    <p className="text-center text-xs">
      You will receive a verification text message via SMS.{' '}
      <span className="sm:whitespace-pre">
        Standard message and data rates may apply.
      </span>
    </p>
  )
}
