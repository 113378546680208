import { TextField } from '@ui/components/inputs/Fields'

export function EmailField({
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'>) {
  return (
    <TextField
      label="Email address"
      name="email"
      type="email"
      autoComplete="email"
      required
      {...props}
    />
  )
}
