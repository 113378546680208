import Link from 'next/link'
import clsx from 'clsx'
import { QRCodeSVG } from 'qrcode.react'
import { Container } from '@ui/components/Container'
import { ButtonAppStore, ButtonGooglePlay } from '@ui/components/buttons'

export const HeroDownload = ({
  className,
  children,
  title = 'Download the app',
  description = (
    <>
      Visit{' '}
      <Link className="text-blueberry-700 underline" href="/download">
        atm.com/download
      </Link>{' '}
      on your phone to download the app.
    </>
  ),
  hideQRCode = false,
}: {
  className?: string
  children?: React.ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
  hideQRCode?: boolean
}) => {
  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center py-4',
        className,
      )}
    >
      <Container>
        <div className="flex flex-col items-center justify-center gap-8 lg:flex-row">
          <div className="flex flex-col items-center justify-center gap-8 lg:w-1/2 lg:items-start">
            <h1 className="font-montserrat h2 sm:h1 text-center lg:text-left">
              {title}
            </h1>
            <div className="hidden flex-row items-start justify-center gap-4 md:flex">
              <ButtonAppStore />
              <ButtonGooglePlay />
            </div>
            <p className="body-1 text-center lg:max-w-sm lg:text-left">
              {description}
            </p>
            {!hideQRCode && (
              <div className="shadow-blueberry-900/20 hidden rounded-xl bg-white p-4 shadow-lg lg:block">
                <QRCodeSVG value="https://www.atm.com/download" />
              </div>
            )}
          </div>
          <div className="flex flex-col items-start justify-center gap-4">
            {children}
          </div>
          <div className="flex flex-row items-center justify-center gap-2 md:hidden">
            <ButtonAppStore />
            <ButtonGooglePlay />
          </div>
        </div>
      </Container>
    </div>
  )
}
