import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const MainBlockWealthBoostImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/2022q3/main-blocks/wealth-boost/desktop@3x.png"
      width="555"
      height="416"
      alt="Ants chart"
    />
  </div>
)
