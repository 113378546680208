import React from 'react'
import Image from 'next/image'
import { Container } from '@ui/components/Container'
import { RegisterFormStandalone } from '@web-app/components/auth/RegisterForm'

export const HeroCashRewards = ({
  className,
  title,
  description,
}: {
  className?: string
  title: React.ReactNode
  description: React.ReactNode
}) => {
  return (
    <div className={className}>
      <Container>
        <div className="flex flex-col gap-8 lg:grid lg:grid-cols-2">
          <div className="order-1 flex flex-col justify-center gap-5 pt-8 md:pt-12 xl:pt-20">
            <h1 className="h1 font-montserrat">{title}</h1>
            <p className="h4">{description}</p>
          </div>
          <div className="order-2 flex items-center justify-center lg:row-span-2">
            <div className="self-center justify-self-center lg:max-w-lg lg:p-4">
              <div className="h5 pb-8 lg:pb-4 lg:text-center">
                Don’t miss out! Sign up now{' '}
                <span className="whitespace-pre">and start earning cash!</span>
              </div>
              <RegisterFormStandalone />
            </div>
          </div>
          <div className="order-3 flex items-center justify-center lg:justify-start">
            <Image
              alt="ATM ant"
              src="/img/hero_cash_rewards@3x.png"
              width={336}
              height={289}
              loading="eager"
              priority={true}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
