import { TextField } from '@ui/components/inputs/Fields'

export function MobileNumberField({
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'>) {
  return (
    <TextField
      label="Mobile phone number"
      name="phone"
      type="tel"
      autoComplete="tel-national"
      required
      {...props}
    />
  )
}
